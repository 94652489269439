import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../components/Hero'
import ServiceCard from '../components/ServiceCard'

import './summit-series.scss'
import sam from '../images/summit/sam.jpg'
import jennifer from '../images/summit/jennifer.png'
import jason from '../images/summit/jason.png'
import sylvie from '../images/summit/sylvie.png'
import header from '../images/summit/SS-Lockup.png'

export default () =>
  <Layout
    className="SummitPage"
    title="Summit Series"
    description="MyHR’s Summit Series are 30 minute webinar events featuring industry leading experts. The webinars are held over two consecutive Tuesdays. Each webinar will be a 30 minute chat followed by a 15 minute Q&A session."
  >
    <Hero variant="normal">
      <img src={header} />
    </Hero>

    <Section>
      <Container>
        <Row>
          <Col md={4} lg={4} className="mb-3 mb-md-0 pr-5">
            <h3>Four <span className="electric-blue">Free</span> Webinars</h3>
            <p>MyHR's Summit Series are 30 minute webinar events featuring industry leading experts. The webinars are held over two consecutive Tuesdays.</p>
            <p>Each webinar will be a 30 minute chat followed by a 15 minute Q&amp;A session.</p>
            <p><strong>Register now to make sure you get a spot.</strong></p>

            <Row className="mt-5 d-flex profile">
              <Col xs={4} md={4} lg={4} className="profile-pic">
                <img src={sam} />
              </Col>
              <Col xs={8} md={8} lg={8} className=" d-flex align-items-center">
                <p><strong>Sam Newton</strong><br />Payroll Expert &amp; Co-founder<br />PayHero</p>
              </Col>
            </Row>

            <Row className="mt-4 d-flex profile">
              <Col xs={4} md={4} lg={4} className="profile-pic">
                <img src={jennifer} />
              </Col>
              <Col xs={8} md={8} lg={8} className=" d-flex align-items-center">
                <p><strong>Jennifer Mills</strong><br />Director<br />Jennifer Mills &amp; Associates</p>
              </Col>
            </Row>

            <Row className="mt-4 d-flex profile">
              <Col xs={4} md={4} lg={4} className="profile-pic">
                <img src={jason} />
              </Col>
              <Col xs={8} md={8} lg={8} className=" d-flex align-items-center">
                <p><strong>Jason Ennor</strong><br />Co-founder &amp; CEO<br />MyHR</p>
              </Col>
            </Row>

            <Row className="mt-4 d-flex profile">
              <Col xs={4} md={4} lg={4} className="profile-pic">
                <img src={sylvie} />
              </Col>
              <Col xs={8} md={8} lg={8} className=" d-flex align-items-center">
                <p><strong>Sylvie Thrush-Marsh</strong><br />Head of Platinum Services<br />MyHR</p>
              </Col>
            </Row>

          </Col>
          <Col md={8} lg={8} className="mb-3 mb-md-0">
            <Row>
              <Col xs={12} className="mb-3 mb-md-0 pr-5">
                <h3>Tuesday 23rd March</h3>
              </Col>
              <Col xs={6} md={6} lg={6} className="mb-3 mb-md-0 pr-5">
                <h6 className="blue">Holidays Act update</h6>
                <h6>Sam Newton, Sylvie Thrush-Marsh</h6>
                <p>Sam and Sylvie talk about upcoming changes to the Holidays Act and how these may impact your business.</p>
                <h6>1–1.45pm</h6>
                <em>This webinar has now ended. <a href="https://www.myhr.works/live/summit-series-holidays-act-update" target="_blank">You can watch the recording here.</a></em>
              </Col>
              <Col xs={6} md={6} lg={6} className="mb-3 mb-md-0 pr-5">
                <h6 className="blue">Running performance reviews</h6>
                <h6>Jason Ennor</h6>
                <p>A live discussion on what performance reviews are good for and how to get the most out of them.</p>
                <h6>2.30–3.15pm</h6>
                <em>This webinar has now ended. <a href="https://www.myhr.works/live/summit-series-running-performance-reviews" target="_blank">You can watch the recording here.</a></em>
              </Col>
            </Row>
            
            <hr />

            <Row>
              <Col xs={12} className="mb-3 mb-md-0 pr-5">
                <h3>Tuesday 30th March</h3>
              </Col>
              <Col xs={6} md={6} lg={6} className="mb-3 mb-md-0 pr-5">
                <h6 className="blue">Employment law update</h6>
                <h6>Jennifer Mills, Jason Ennor</h6>
                <p>Jennifer and Jason discuss how to navigate the changing landscape of employment law.</p>
                <h6>1–1.45pm</h6>
                <em>This webinar has now ended. <a href="https://www.myhr.works/live/summit-series-employment-law-update" target="_blank">You can watch the recording here.</a></em>
              </Col>
              <Col xs={6} md={6} lg={6} className="mb-3 mb-md-0 pr-5">
                <h6 className="blue">Running a disciplinary process</h6>
                <h6>Sylvie Thrush-Marsh</h6>
                <p>Sylvie will talk about gaining clarity and confidence in one of the most dreaded HR processes.</p>
                <h6>2.30–3.15pm</h6>
                <em>This webinar has now ended. <a href="https://www.myhr.works/live/summit-series-running-a-disciplinary-process" target="_blank">You can watch the recording here.</a></em>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>

