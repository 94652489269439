import React from 'react'

import './ServiceCard.scss'

import safetyPlusImg from '../images/section/safetyplus.png'
import recruitPlusImg from '../images/section/recruitplus.png'
import consultPlusImg from '../images/section/consultplus.png'

export default ({
  name,
  cost,
  costSuffix,
  description,
  uiColor = 'electric-blue',
  children = null
}) => 
  <div className="ServiceCard">
    <div className={`ServiceCard__head bg-gradient-${uiColor}`}>
      <div className="ServiceCard__name">
        {name=='Safety PLUS' && <img src={safetyPlusImg} height="24" />}
        {name=='Recruit PLUS' && <img src={recruitPlusImg} height="24" />}
        {name=='Consult PLUS' && <img src={consultPlusImg} height="24" />}
      </div>
      <div className="ServiceCard__cost">
        {cost}
        {costSuffix &&
          <small>{costSuffix}</small>
        }
      </div>
    </div>
    <div className="ServiceCard__body">
      {description && description.childMarkdownRemark && description.childMarkdownRemark.html &&
        <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
      }
      {children}
    </div>
  </div>